import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';
import Modal from 'react-modal';
import { PHRPagination } from '@palmhr/palmhr-ui-lib';

import AnchorModal from './AnchorModal';
import styles from './Anchors.module.scss';
import AnchorTableRow from './AnchorTableRow';
import AnchorApi from '../api/AnchorApi';
import ConfirmModal from '../components/ConfirmModal/ConfirmModal';
import modalStyle from '../components/modalStyles';
import Search from '../components/Search';
import TableSkeleton from '../components/skeletons/TableSkeleton';
import TableHead from '../components/table/TableHead';
import useDebounce from '../Shared/useDebounce';

const addModalStyle = _.cloneDeep(modalStyle);
addModalStyle.content.width = '540px';

const confirmModal = _.cloneDeep(modalStyle);
confirmModal.content.width = '720px';

const Anchors = () => {
  const [anchors, setAnchors] = useState([]);
  const [initialAnchors, setInitalAnchors] = useState([]);
  const [anchorsPagination, setAnchorsPagination] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [selectedAnchor, setSelectedAnchor] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    currentPage: 1,
    perPage: 5,
  });

  const headerCells = [{ title: 'Key' }, { title: 'Routes' }, { title: 'Created By', colSpan: 2 }];

  useEffect(() => {
    AnchorApi.getListOfAnchors(params).then((res) => {
      setLoading(true);
      setAnchors(res.data.items);
      setAnchorsPagination(res.data.pagination || []);
      setInitalAnchors(res.data.items);
      setLoading(false);
    });
  }, [params]);

  useEffect(() => {
    const arr = [];
    _.filter(initialAnchors, (anchor) => {
      if (anchor.anchorKey.toLowerCase().includes(searchTerm.toLowerCase())) {
        arr.push(anchor);
      }
    });
    setAnchors(arr);
  }, [debouncedSearchTerm]);

  const makeNewAnchor = (data) => {
    AnchorApi.createAnchor(data).then((res) => {
      setAnchors([...anchors, res.data]);
    });
  };

  const updatePaginationParams = ({ currentPage, perPage }) =>
    setParams((prevState) => ({ ...prevState, currentPage, perPage }));

  const handleRemoveSpecificRow = (id) => {
    const items = anchors.filter((anchor) => anchor.id !== id);
    setAnchors(items);
  };

  const removeData = () => {
    AnchorApi.deleteAnchor(selectedAnchor.id).then(() => {
      handleRemoveSpecificRow(selectedAnchor.id);
      setShowDeleteModal(false);
    });
  };

  const editAnchor = (id, data) =>
    AnchorApi.updateAnchors(id, data).then((res) => {
      const editedAnchor = [...anchors];
      const anchorIndex = editedAnchor.findIndex((item) => item.id === id);
      editedAnchor[anchorIndex] = res.data;
      setAnchors(editedAnchor);
      setShowAddModal(false);
    });

  const editHandler = (anchor) => {
    setShowAddModal(true);
    setSelectedAnchor(anchor);
  };

  const deleteHandler = (anchor) => {
    setShowDeleteModal(true);
    setSelectedAnchor(anchor);
  };

  const onAddNewAnchor = () => {
    setShowAddModal(true);
    setSelectedAnchor(null);
  };

  return (
    <div className='mt-3 mx-3'>
      <div className={(cx(styles.wrapper), 'panel p-0')}>
        <div className='main-content container-fluid px-0 bg-white'>
          <div className={cx('px-5 py-4 border-btm d-flex justify-content-between align-items-center')}>
            <div>
              <h2>Anchors</h2>
              <p className='tab-subtitle'>Click on the anchor text to reach the link target</p>
            </div>
            <button type='button' onClick={onAddNewAnchor} className='btn btn-primary text-white align-middle mt-n5'>
              <div className='d-flex align-items-center justify-content-between'>
                <span>Add New</span>
                <i className='icon-plus ms-2' />
              </div>
            </button>
          </div>

          <div>
            <div className='row px-6'>
              <div className='col-4 d-flex align-items-center position-relative p-0' style={{ height: '55px' }}>
                <Search
                  onChange={(e) => {
                    setSearchTerm(e.target.value.replace(/^\s+/g, ''));
                  }}
                  placeholder='Search anchors'
                  value={searchTerm}
                  onClear={() => {
                    setSearchTerm('');
                  }}
                />
              </div>
            </div>
            <div
              className='d-flex justify-content-start flex-column gap-2 mx-5'
              style={{ height: 'calc(100vh - 180px)' }}
            >
              <Scrollbars autoHide className='scroll-bar h-100'>
                <div className='pb-7 mb-7'>
                  <table className='w-100'>
                    <TableHead headerCells={headerCells} className={styles.fixedHeader} />
                    <tbody>
                      {anchors &&
                        anchors.map((anchor) => (
                          <AnchorTableRow
                            key={anchor.id}
                            data={anchor}
                            onEditModal={() => {
                              editHandler(anchor);
                            }}
                            onDeleteModal={() => {
                              deleteHandler(anchor);
                            }}
                          />
                        ))}
                      {loading && (
                        <tr className={(cx(styles.clientList), 'w-100')}>
                          <td colSpan='5'>
                            <TableSkeleton lineHight={45} mb='mb-2' num={5} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Scrollbars>
              {anchorsPagination?.total > 0 && (
                <PHRPagination onPaginationAction={updatePaginationParams} pagination={anchorsPagination} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showAddModal}
        contentLabel='Upgrade Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={addModalStyle}
        onRequestClose={() => {
          setShowAddModal(false);
        }}
      >
        <AnchorModal
          makeNewAnchor={makeNewAnchor}
          editAnchor={editAnchor}
          onClose={() => setShowAddModal(false)}
          selectedAnchor={selectedAnchor}
          anchors={anchors}
        />
      </Modal>
      <Modal
        isOpen={showDeleteModal}
        contentLabel='Upgrade Client'
        ariaHideApp={false}
        closeTimeoutMS={300}
        style={confirmModal}
        onRequestClose={() => {
          setShowDeleteModal(false);
        }}
      >
        <ConfirmModal
          onClose={() => {
            setShowDeleteModal(false);
          }}
          onSubmit={() => {
            removeData();
          }}
          modalName='Delete Anchor'
          confirmName='Delete'
          confirmText='Are you sure you want to delete selected anchor?'
        />
      </Modal>
    </div>
  );
};

export default Anchors;
